<template>
  <div class="world detail">
    <div class="detail max-w-7xl px-5 mx-auto relative pb-20 md:pb-12 pt-10" v-if="item">
      <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 pb-4 block">&larr; {{ $t('Back to overview') }}</router-link>
      <h2 class="font-700 text-wilmsBlue text-2xl">{{ getTranslation(item).name }}</h2>

      <div class="flex items-end justify-between sm:flex-wrap">
        <ul class="flex text-wilmsGrey mt-6 flex-wrap sm:w-full sm:mb-4">
          <li :class="{ 'active': !activeCategory }" class="mr-4 cursor-pointer" @click="filterByCategory(null)">{{ $t('Alles') }}</li>

          <li :class="{'active': activeCategory && activeCategory.id === category.id }" class="mr-4 font-100 cursor-pointer" @click="filterByCategory(category)" :key="'category-' + category.id" v-for="category in categories">
            {{ getTranslation(category).name }}
          </li>
        </ul>
        <input @input="filterByText" v-model="search" type="text" class="search shadow-xl p-2 leading-none pl-10 rounded-sm text-wilmsGrey bg-white" :placeholder="$t('Zoek')"/>
      </div>

      <div class="shadow-lg rounded-sm bg-white p-2 mt-6">
        <div class="flex bg-wilmsLightGrey py-3 rounded-sm text-wilmsBlue font-700">
          <div class="px-5 w-1/3 sm:w-1/2">{{ $t('Document name') }}</div>
          <div class="px-5 sm:w-1/2">{{ $t('Type') }}</div>
        </div>

        <div class="flex flex-col list">
          <div class="row flex flex-wrap items-center border-b" :key="'item-' + item.id" v-for="item in files">
            <div class="w-1/3 name p-4 px-5 sm:w-1/2 text-wilmsBlue">{{ getTranslation(item).name }}</div>
            <div class="p-4 px-5 flex-1 sm:w-1/2 text-wilmsBlue flex justify-between items-center">
              <span>{{ getTranslation(getCategory(item.parent_id)).name }}</span>
            </div>
            <div class="ml-auto p-4 sm:w-full sm:pt-0">
              <a v-if="item.type === 'file'" :href="filePaths[getTranslation(item).file_id].url" download class="downloadButton inline-block text-sm cursor-pointer pl-10 pr-4 py-1 font-600 text-white bg-wilmsBlue border-2 border-wilmsBlue rounded-sm transition duration-300 ease-in-out hover:shadow-xl transform hover:-translate-y-1">{{ $t('Download document') }}</a>
              <a v-else :href="getTranslation(item).url" target="_blank" class="inline-block text-sm cursor-pointer pl-4 pr-4 py-1 font-600 text-white bg-wilmsBlue border-2 border-wilmsBlue rounded-sm transition duration-300 ease-in-out hover:shadow-xl transform hover:-translate-y-1">{{ $t('Openen') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      isLoading: false,
      itemId: this.$route.params.id,
      item: null,
      files: [],
      filesNoFilter: [],
      search: '',
      categories: [],
      activeCategory: null
    }
  },
  async mounted () {
    await this.getItem()
  },
  methods: {
    filterByCategory (category) {
      this.activeCategory = category

      this.files = []

      if (!category) {
        this.files = this.item.children.filter(item => item.type !== 'category')
      }

      this.categories.forEach((childCategory) => {
        if (!category || (childCategory.type === 'category' && childCategory.id === category.id)) {
          this.files = this.files.concat(childCategory.children.filter(item => item.type !== 'category'))
        }
      })

      this.files = this.files.filter(item => this.hasTrans(item))

      this.filesNoFilter = this.files

      this.filterByText()
    },
    filterByText () {
      if (this.search !== '') {
        this.files = this.filesNoFilter.filter((item) => {
          return this.getTranslation(item).name.toLowerCase().includes(this.search.toLowerCase())
        })
      } else {
        this.files = this.filesNoFilter
      }
    },
    async getItem () {
      const response = await ApiService.fetchMenuItem(this.itemId)
      if (response.status === 200) {
        this.item = response.data

        this.categories = this.item.children.filter(item => item.type === 'category')

        this.filterByCategory(null)

        await this.$store.dispatch('fetchImages', { objects: this.files, value: 'file_id', translations: true })
      }
    },
    getCategory (id) {
      return this.categories.find((item) => item.id === id) || ''
    }
  },
  computed: {
    filePaths () {
      return this.$store.getters.getImages
    }
  }
}
</script>
